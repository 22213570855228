import React, {useState, useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeaturesSection";
import HowItWorksSection from "./components/HowItWorksSection";
import Footer from "./components/Footer";
import AuthPage from "./components/AuthPage";
import KnowledgeBase from "./components/KnowledgeBase";
import Dashboard from "./components/Dashboard";
import ResetPassword from "./components/ResetPassword";
import SignUp from "./components/SignUp";
import Account from "./components/Accounts";
import ContactUs from "./components/ContactUs";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicies from "./components/PrivacyPolicies";
import About from "./components/About";
import "./App.css";
import StatsSection from "./components/StatsSection";
import MissionEmpowering from "./components/MissionEmpowering";
import MissionHowItWorks from "./components/MissionHowItWorks";
import FeaturesSectionNew from "./components/FeaturesSectionNew";
import BlogGrid from "./components/BlogGrid";
import BlogCard from "./components/BlogCard";
import BlogDetail from "./components/BlogDetail";
import {useNavigate} from 'react-router-dom';

const App = () => {
    const location = useLocation(); // Now it works because App is inside Router
    const navigate = useNavigate();

    useEffect(() => {
        if (typeof window.gtag === "function") {
            window.gtag("config", "G-VHQR7N5W71", {
                page_path: location.pathname,
            });
        }
    }, [location]);

    const [username, setUsername] = useState(() => localStorage.getItem("username") || null);

    useEffect(() => {
        if (username) {
            localStorage.setItem("username", username);
        } else {
            localStorage.removeItem("username");
        }
    }, [username]);

    const handleLogin = (user) => {
        setUsername(user);
    };

    const handleSignup = (user) => {
        setUsername(user);
    };

    const handleLogout = () => {
        localStorage.removeItem("username");
        setUsername(null);
        navigate("/");
    };

    return (
        <div className="AppContainer">
            <Header username={username} onLogout={handleLogout}/>
            <main className="Content">
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <HeroSection/>
                                <StatsSection/>
                                <MissionHowItWorks/>
                                <FeaturesSectionNew/>

                            </>
                        }
                    />
                    <Route path="/auth" element={<AuthPage onLogin={handleLogin}/>}/>
                    <Route path="/learning" element={<KnowledgeBase/>}/>
                    <Route path="/blog" element={<BlogGrid/>}/>
                    <Route
                        path="/dashboard"
                        element={
                            username ? <Dashboard username={username}/> : <AuthPage onLogin={handleLogin}/>
                        }
                    />
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/signup" element={<SignUp onSignup={handleSignup}/>}/>
                    <Route
                        path="/account"
                        element={
                            username ? <Account username={username}/> : <AuthPage onLogin={handleLogin}/>
                        }
                    />
                    <Route path="/contact-us" element={<ContactUs/>}/>
                    <Route path="/terms" element={<TermsAndConditions/>}/>
                    <Route path="/privacy" element={<PrivacyPolicies/>}/>
                    <Route path="/about" element={<About/>}/>

                    <Route exact path="/" component={BlogGrid}/>
                    <Route path="/blog/:blogId" element={<BlogDetail />} />
                </Routes>
            </main>
            <Footer/>
        </div>
    );
};

export default App;
