import React, { useState, useMemo } from "react";
import { useTransactions } from "./useTransactions";
import { useFilters } from "./useFilters";
import "./FrameStyles.css";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";
import SummaryCard from "./SummaryCard";

const ITEMS_PER_PAGE = 5000000;

function WashSaleAvoidance({ username }) {
    const [expandedTransaction, setExpandedTransaction] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: "profitLoss", direction: "asc" });

    const toggleExpand = (index) => {
        setExpandedTransaction(expandedTransaction === index ? null : index);
    };

    const { data: rawData, loading, error } = useTransactions(
        "transactions/getWashSaleAvoidanceCompanies",
        username
    );

    const data = Array.isArray(rawData) ? rawData : [];
    const {
        filteredData: unpagedData,
        setYearFilter,
        setTickerFilter,
    } = useFilters(data, ITEMS_PER_PAGE);

    // Sort filtered data based on the current sort configuration
    const sortedData = useMemo(() => {
        if (!sortConfig.key) return unpagedData;

        return [...unpagedData].sort((a, b) => {
            const aValue = a[sortConfig.key] ?? 0;
            const bValue = b[sortConfig.key] ?? 0;

            if (sortConfig.direction === "asc") return aValue < bValue ? -1 : 1;
            return aValue > bValue ? -1 : 1;
        });
    }, [unpagedData, sortConfig]);

    // Calculate summary values
    const totalLossAmount = sortedData.reduce((sum, transaction) => sum + (transaction.profitLoss || 0), 0);
    const numberOfTickersToAvoid = sortedData.length;

    // Sorting handler
    const handleSort = (key) => {
        setSortConfig((prev) => ({
            key,
            direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
        }));
    };

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Lottie animationData={loadingAnimation} style={{ height: "150px", width: "150px" }} />
            </div>
        );
    }

    if (error) return <div>Error: {error}, please contact support for help: support@profitree-tax.com</div>;

    return (
        <div>
            <div className="summary-container">
                <SummaryCard title="Total Loss Amount" value={totalLossAmount} prefix="$" />
                <SummaryCard title="No. of Tickers To Avoid" value={numberOfTickersToAvoid} />
            </div>
            <div className="filters">
                <label>
                    Year:
                    <select onChange={(e) => setYearFilter(e.target.value)}>
                        <option value="">All</option>
                        {[...new Set(data.map((d) => new Date(d.date).getFullYear()))].map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </label>
                <label>
                    Ticker Symbol:
                    <input
                        type="text"
                        placeholder="Enter ticker symbol"
                        onChange={(e) => setTickerFilter(e.target.value)}
                    />
                </label>
            </div>

            {/* Desktop Table View */}
            <div className="table-container desktop-view">
                <table className="transactions-table">
                    <thead>
                    <tr>
                        <th onClick={() => handleSort("accountName")}>Account Name</th>
                        <th onClick={() => handleSort("tickerSymbol")}>Ticker</th>
                        <th onClick={() => handleSort("transactionType")}>Transaction Type</th>
                        <th onClick={() => handleSort("purchaseDate")}>Purchase Date</th>
                        <th onClick={() => handleSort("purchasePrice")}>Purchase Price</th>
                        <th onClick={() => handleSort("quantity")}>Sold Quantity</th>
                        <th onClick={() => handleSort("soldDate")}>Sold Date</th>
                        <th onClick={() => handleSort("soldPrice")}>Sold Price</th>
                        <th onClick={() => handleSort("profitLoss")}>Loss Amt</th>
                        <th onClick={() => handleSort("daysToAvoidWashSale")}>Days to Avoid</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedData.map((transaction, index) => (
                        <tr key={index}>
                            <td>{transaction.accountName ?? "Unknown"}</td>
                            <td>{transaction.tickerSymbol}</td>
                            <td>{transaction.transactionType ?? "Unknown"}</td>
                            <td>
                                {transaction.settlementTransactions[0]?.date != null
                                    ? new Date(transaction.settlementTransactions[0].date).toLocaleDateString() : "Unknown Date"}
                            </td>
                            <td>${transaction.settlementTransactions[0].price?.toFixed(2)}</td>
                            <td>{transaction.quantity?.toFixed(2)}</td>
                            <td>{new Date(transaction.date).toLocaleDateString()}</td>
                            <td>${transaction.price?.toFixed(2)}</td>
                            <td>${Math.round(transaction.profitLoss)}</td>
                            <td>{transaction.daysToAvoidWashSale?.toFixed(2) ?? "0"}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Mobile Card View */}
            <div className="mobile-view">
                <div className="transaction-header-row">
                    <div className="header-item">Ticker</div>
                    <div className="header-item">Loss</div>
                </div>
                {sortedData.map((transaction, index) => (
                    <div
                        key={transaction.tickerSymbol + index}
                        className={`transaction-card ${
                            expandedTransaction === index ? "expanded" : ""
                        }`}
                    >
                        <div className="transaction-summary" onClick={() => toggleExpand(index)}>
                            <div className="transaction-header">
                                <span className="ticker">{transaction.tickerSymbol}</span>
                                <span className="loss">${Math.abs(transaction.profitLoss).toFixed(2)}</span>
                            </div>
                        </div>
                        {expandedTransaction === index && (
                            <div className="transaction-details">
                                <p>
                                    <strong>Account Name:</strong> {transaction.accountName}
                                </p>
                                <p>
                                    <strong>Purchase Date:</strong>{" "}
                                    {transaction.purchaseDate
                                        ? new Date(transaction.purchaseDate).toLocaleDateString()
                                        : "N/A"}
                                </p>
                                <p>
                                    <strong>Purchase Price:</strong> ${transaction.purchasePrice?.toFixed(2)}
                                </p>
                                <p>
                                    <strong>Sold Date:</strong>{" "}
                                    {transaction.soldDate
                                        ? new Date(transaction.soldDate).toLocaleDateString()
                                        : "N/A"}
                                </p>
                                <p>
                                    <strong>Sold Price:</strong> ${transaction.soldPrice?.toFixed(2)}
                                </p>
                                <p>
                                    <strong>Loss Amount:</strong> ${Math.abs(transaction.profitLoss).toFixed(2)}
                                </p>
                                <p>
                                    <strong>Days to Avoid:</strong> {transaction.daysToAvoidWashSale?.toFixed(2)}
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WashSaleAvoidance;
