import React from "react";
import "./Pricing.css"; // Importing the CSS file

export const Card = ({ children, className }) => (
    <div className={`card ${className}`}>{children}</div>
);

export const CardHeader = ({ children }) => (
    <div className="card-header">{children}</div>
);

export const CardContent = ({ children }) => (
    <div className="card-content">{children}</div>
);

export const Button = ({ children, className, ...props }) => (
    <button className={`button ${className}`} {...props}>
        {children}
    </button>
);

const Pricing = () => {
    const plans = [
        {
            title: "Trader Plan",
            price: "$15/month",
            features: [
                "Wash sale tracking & avoidance",
                "Options trading tracking",
                "View past wash sale transactions to avoid costly mistakes",
                "Alerts for potential wash sales",
                "Detailed insights on disallowed losses",
            ],
            buttonLabel: "Start 1 Month Free Trial",
        },
        {
            title: "Investor Plan",
            price: "1% of tax savings shown",
            features: [
                "View current year capital gains and losses",
                "Identify tax-saving opportunities via tax loss harvesting",
                "Track gains to avoid short-term taxes",
                "Custom cost-basis strategies like FIFO, LIFO",
                "Projected tax savings calculator",
            ],
            buttonLabel: "Start 1 Month Free Trial",
        },
        {
            title: "Trader + Investor Plan",
            price: "$15/month + 1% of tax savings shown",
            features: [
                "All features from the Trader Plan",
                "All features from the Investor Plan",
                "Comprehensive portfolio management",
                "Custom alerts for wash sales and tax-saving opportunities",
                "Priority customer support",
            ],
            buttonLabel: "Start 1 Month Free Trial",
        },
    ];

    return (
        <div className="pricing-container">
            <div className="free-trial-banner">
                <span className="trial-badge">All plans FREE for 1 month!</span>
                <h1 className="pricing-title">Choose Your Plan</h1>
                <p className="pricing-description">
                    Start your journey to optimize your tax savings today. <strong>No credit card required!</strong>
                </p>
            </div>
            <div className="pricing-grid">
                {plans.map((plan, index) => (
                    <Card key={index} className="pricing-card">
                        <CardHeader>
                            <h2 className="card-title">{plan.title}</h2>
                            <p className="card-price">{plan.price}</p>
                        </CardHeader>
                        <CardContent>
                            <ul className="card-features">
                                {plan.features.map((feature, idx) => (
                                    <li key={idx} className="card-feature">
                                        <span className="feature-icon">✔</span>
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                            <div className="card-button-container">
                                <Button className="card-button">{plan.buttonLabel}</Button>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default Pricing;
