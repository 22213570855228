import React from 'react';
import { useParams } from 'react-router-dom';
import blogData from './blogData';
import './BlogDetail.css';

const BlogDetail = () => {
    const { blogId } = useParams();
    const blog = blogData.find((b) => b.id === blogId);

    if (!blog) {
        return <div className="blog-not-found">Blog not found.</div>;
    }

    return (
        <div className="blog-detail">
            <header className="blog-header">
                <h1 className="blog-title">{blog.title}</h1>
                <p className="blog-date">{blog.date}</p>
            </header>
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <article
                className="blog-content"
                dangerouslySetInnerHTML={{ __html: blog.content }}
            />
            <footer className="blog-footer">
                <p>Share this article:</p>
                <div className="social-icons">
                    <a href={`https://twitter.com/intent/tweet?text=${blog.title}`} target="_blank" rel="noopener noreferrer">Twitter</a>
                    <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} target="_blank" rel="noopener noreferrer">Facebook</a>
                </div>
            </footer>
        </div>
    );
};

export default BlogDetail;
