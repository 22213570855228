import React, { useState, useEffect } from "react";
import { SnapTradeReact } from 'snaptrade-react';
import axios from "axios";
import "./AccountManager.css";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com"; // Your backend URL

function Accounts({ username }) {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [loginLink, setLoginLink] = useState('');
    const [expandedAccount, setExpandedAccount] = useState(null);

    useEffect(() => {
        fetchAccounts();
    }, []);

    const fetchAccounts = async () => {
        try {
            const response = await axios.post(`${backendUrl}/accounts/getList`, null, {
                params: { username },
            });
            if (response.status === 200 && response.data) {
                setAccounts(response.data);
            } else {
                setError("Failed to fetch accounts.");
            }
        } catch (err) {
            console.error("Error fetching accounts:", err);
            setError("Failed to load accounts.");
        }
    };

    const toggleExpand = (accountId) => {
        setExpandedAccount(expandedAccount === accountId ? null : accountId);
    };


    const handleConnect = async () => {
        try {
            const response = await axios.post(`${backendUrl}/accounts/create`, null, {
                params: { username },
            });
            if (response.status === 200) {
                const snapTradeUrl = response.data;
                setLoginLink(snapTradeUrl);
                setIsOpen(true);
            }
        } catch (err) {
            console.error("Error fetching accounts:", err);
        }
    };

    const handleSuccess = async (brokerageAuthorizationId) => {
        setIsOpen(false);
        setLoading(true);
        try {
            console.log("Authorization ID:", brokerageAuthorizationId);
            const response = await axios.post(`${backendUrl}/accounts/setup`, null, {
                params: {username, brokerageAuthorizationId},
            });
            //setData(response.data);
            if (response.status === 200 && response.data) {
                if(response.data === "Account already exists") {
                    alert("Account already exists.");
                }
                if(response.data === "Username/brokerage auth is empty or null") {
                    alert("Invalid user. Please sign-in again.");
                }
                fetchAccounts();
            } else {
                setError("Failed to fetch accounts.");
            }
        } catch (err) {
            console.error("Error fetching accounts:", err);
            alert("Please try to reconnect the account.");
            setError("Failed to load accounts.");
        } finally {
            setLoading(false);
        }
        // Proceed with activating the account using the authorizationId
    };
    const handleError = async(error) => {
        console.log('clean up account for user:', username);
        const response = await axios.post(`${backendUrl}/accounts/cleanup`, null, {
            params: {username, error},
        });
        alert("Connection error. Please try again.");
        setIsOpen(false);
    };
    const handleExit = async () => {
        setIsOpen(false);
    };
    const toggleAccountStatus = async (accountId, currentStatus) => {
        try {
            const response = await axios.post(`${backendUrl}/accounts/flip`, null, {
                params: { username, accountId },
            });
            if (response.status === 200) {
                setAccounts((prevAccounts) => prevAccounts.map((account) => account.accountId === accountId ? {
                    ...account,
                    enabled: !currentStatus
                } : account));
                // Reload the page after 2 seconds
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                setError("Failed to update account status.");
            }
        } catch (err) {
            console.error("Error toggling account status:", err);
            setError("Failed to update account status.");
        }
    };

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };
    if (loading) {
        return (<div
                style={{
                    display: "flex", justifyContent: "center", alignItems: "center"
                }}
            >
                <Lottie
                    animationData={loadingAnimation}
                    style={{height: "150px", width: "150px"}} // Adjust loader size
                />
            </div>);
    }
    if (error) return <div className="error">{error}</div>;

    return (<div className="account-manager">
            <h1>Account Manager</h1>
            <div>
                <div className="tooltip-container">
                    <button className="add-account-button" onClick={handleConnect}>
                        Connect Account
                    </button>
                    <span className="tooltip">
                    <strong>Your Data is Safe:</strong> ProfiTree connects securely via SnapTrade using
                advanced encryption and read-only access.
                    </span>
                </div>
                {isOpen && (<SnapTradeReact
                        loginLink={loginLink}
                        isOpen={isOpen}
                        onSuccess={handleSuccess}
                        onError={handleError}
                        onExit={handleExit}
                        close={() => setIsOpen(false)}
                    />)}
            </div>

            {/* Table for Larger Screens */}
            <div className="responsive-container">
                <table className="responsive-table">
                    <thead>
                    <tr>
                        <th>Account Name</th>
                        <th>Created Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {accounts.map((account) => (
                        <tr key={account.accountId}>
                            <td>{account.accountName}</td>
                            <td>{account.createdDate}</td>
                            <td>{account.enabled ? "Enabled" : "Disabled"}</td>
                            <td>
                                {account.enabled ? (<button
                                    className="status-disable"
                                    onClick={() => toggleAccountStatus(account.accountId, false)}
                                >
                                    Disable
                                </button>) : (<button
                                    className="action-button-enable"
                                    onClick={() => toggleAccountStatus(account.accountId, true)}
                                >
                                    Enable
                                </button>)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                {/* Card View for Mobile Screens */}
                <div className="responsive-cards">
                    {accounts.map((account) => (
                        <div key={account.accountId} className="account-row">
                            <div className="account-summary" onClick={() => toggleExpand(account.accountId)}>
                                <div className="account-name">
                                    {account.accountName}
                                    {expandedAccount === account.accountId ? <FaChevronUp className="chevron-icon" /> : <FaChevronDown className="chevron-icon" />}
                                </div>
                            </div>
                            {expandedAccount === account.accountId && (
                                <div className="account-details">
                                    <p><strong>Created:</strong> {account.createdDate}</p>
                                    <p><strong>Status:</strong> {account.enabled ? "Enabled" : "Disabled"}</p>
                                    <button
                                        className={`status-${account.enabled ? "disable" : "enable"}`}
                                        onClick={() => toggleAccountStatus(account.accountId, account.enabled)}
                                    >
                                        {account.enabled ? "Disable" : "Enable"}
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Accounts;
