import React from "react";
import "./DemoComponent.css";

const ManualTransactions = ({ transactions, setTransactions }) => {
    const addTransaction = () => {
        const newTransaction = { id: Date.now(), date: "", ticker: "", qty: 0, price: 0, cost: 0 };
        setTransactions([...transactions, newTransaction]);
    };

    const updateTransaction = (id, field, value) => {
        setTransactions(
            transactions.map((txn) =>
                txn.id === id ? { ...txn, [field]: value } : txn
            )
        );
    };

    const deleteTransaction = (id) => {
        setTransactions(transactions.filter((txn) => txn.id !== id));
    };

    return (
        <div>
            <h3>Transaction Data</h3>
            <table className="transaction-table" border="1" width="100%">
                <thead>
                <tr>
                    <th>Account</th>
                    <th>Ticker</th>
                    <th>Date</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Type</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {transactions.map((txn) => (
                    <tr key={txn.id}>
                        <td>
                            <input
                                type="text"
                                value={txn.accountName}
                                onChange={(e) => updateTransaction(txn.id, "accountName", e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={txn.instrument_id}
                                onChange={(e) => updateTransaction(txn.id, "instrument_id", e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="date"
                                value={txn.date.split("T")[0]} // Extract only the date part for the input
                                onChange={(e) => {
                                    // Convert the selected date to LocalDateTime format
                                    const updatedDate = `${e.target.value}T00:00:00`; // Add time component
                                    updateTransaction(txn.id, "date", updatedDate);
                                }}
                            />
                        </td>

                        <td>
                            <input
                                type="number"
                                value={txn.quantity}
                                onChange={(e) => updateTransaction(txn.id, "quantity", e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="number"
                                value={txn.price}
                                onChange={(e) => updateTransaction(txn.id, "price", e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={txn.type}
                                onChange={(e) => updateTransaction(txn.id, "type", e.target.value)}
                            />
                        </td>
                        <td>
                            <button onClick={() => deleteTransaction(txn.id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <button className="add-transaction" onClick={addTransaction}>+ Add Transaction</button>
        </div>
    );
};

export default ManualTransactions;
