import React, { useState, useEffect } from "react";
import { useTransactions } from "./useTransactions";
import { useFilters } from "./useFilters";
import "./FrameStyles.css";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";
import CountUp from "react-countup";
import SummaryCard from "./SummaryCard";

const ITEMS_PER_PAGE = 5000000;

function TaxLossOpportunitiesFrame({ username }) {
    const [totalCapitalGain, setTotalCapitalGain] = useState(0);
    const [totalCapitalLoss, setTotalCapitalLoss] = useState(0);
    const [expandedTransaction, setExpandedTransaction] = useState(null); // Track expanded card
    const [sortConfig, setSortConfig] = useState({ key: "totalTaxSavings", direction: "desc" }); // Default sorting

    // Fetch data for capital gains and losses
    const { data: gainData, loading: gainLoading, error: gainError } = useTransactions(
        "transactions/getCurrentYearTaxableProfit",
        username
    );
    const { data: lossData, loading: lossLoading, error: lossError } = useTransactions(
        "transactions/getCurrentYearTaxableLoss",
        username
    );
    const { data: transactionsData, loading: transactionsLoading, error: transactionsError } =
        useTransactions("transactions/getPotentialLossOpportunities", username);

    const {
        filteredData: unpagedData,
        totalPages,
        setYearFilter,
        setTickerFilter,
        currentPage,
        setCurrentPage,
    } = useFilters(transactionsData, ITEMS_PER_PAGE);

    // Calculate total capital gain and loss
    useEffect(() => {
        if (gainData) {
            const totalGain = gainData.reduce((acc, transaction) => acc + transaction.profitLoss, 0);
            setTotalCapitalGain(totalGain);
        }
        if (lossData) {
            const totalLoss = lossData.reduce((acc, transaction) => acc + transaction.profitLoss, 0);
            setTotalCapitalLoss(totalLoss);
        }
    }, [gainData, lossData]);

    // Sorting logic
    const sortedData = React.useMemo(() => {
        if (!sortConfig.key) return unpagedData;

        return [...unpagedData].sort((a, b) => {
            const aValue =
                sortConfig.key === "totalTaxSavings"
                    ? Math.abs(a.profitLoss * 0.3)
                    : a[sortConfig.key];
            const bValue =
                sortConfig.key === "totalTaxSavings"
                    ? Math.abs(b.profitLoss * 0.3)
                    : b[sortConfig.key];

            if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [unpagedData, sortConfig]);

    const toggleExpand = (index) => {
        setExpandedTransaction(expandedTransaction === index ? null : index); // Toggle expand/collapse
    };

    // Calculate total tax savings for filtered data
    const totalTaxSavings = sortedData.reduce((acc, transaction) => {
        const taxRate = 0.3;
        return acc + Math.abs(transaction.profitLoss * taxRate); // Accumulate absolute tax savings
    }, 0);

    const totalNetCapitalGain = totalCapitalGain + totalCapitalLoss;

    // Handle sorting
    const handleSort = (key) => {
        setSortConfig((prev) => {
            const direction = prev.key === key && prev.direction === "asc" ? "desc" : "asc";
            return { key, direction };
        });
    };

    if (gainLoading || lossLoading || transactionsLoading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Lottie
                    animationData={loadingAnimation}
                    style={{ height: "150px", width: "150px" }} // Adjust loader size
                />
            </div>
        );
    }

    if (gainError || lossError || transactionsError) {
        return (
            <div>
                Error: {gainError || lossError || transactionsError}. Please contact support for
                help: support@profitree-tax.com
            </div>
        );
    }

    return (
        <div>
            <div className="summary-container">
                <SummaryCard title="Total Capital Gain" value={totalCapitalGain} prefix="$"/>
                <SummaryCard title="Total Capital Loss" value={totalCapitalLoss} prefix="$"/>
                <SummaryCard title="Total Net Gain" value={totalNetCapitalGain} prefix="$"/>
                <SummaryCard title="Total Tax Saving Opportunities" value={totalTaxSavings} prefix="$"/>
            </div>
            <div className="filters">
                <label>
                    Year:
                    <select onChange={(e) => setYearFilter(e.target.value)}>
                        <option value="">All</option>
                        {[...new Set(transactionsData.map((d) => new Date(d.date).getFullYear()))].map(
                            (year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            )
                        )}
                    </select>
                </label>
                <label>
                    Ticker Symbol:
                    <input
                        type="text"
                        placeholder="Enter ticker symbol"
                        onChange={(e) => setTickerFilter(e.target.value)}
                    />
                </label>
            </div>

            {/* Desktop Table View */}
            <div className="table-container desktop-view">
                <table className="transactions-table">
                    <thead>
                    <tr>
                        <th onClick={() => handleSort("accountName")}>Account Name</th>
                        <th onClick={() => handleSort("tickerSymbol")}>Ticker</th>
                        <th onClick={() => handleSort("transactionType")}>Transaction Type</th>
                        <th onClick={() => handleSort("date")}>Purchase Date</th>
                        <th onClick={() => handleSort("quantity")}>Quantity</th>
                        <th onClick={() => handleSort("price")}>Purchase Price</th>
                        <th onClick={() => handleSort("currentPrice")}>Current Price</th>
                        <th onClick={() => handleSort("profitLoss")}>Potential Loss</th>
                        <th>Tax Type</th>
                        <th onClick={() => handleSort("totalTaxSavings")}>Tax Savings</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedData.map((transaction, index) => (
                        <tr key={index}>
                            <td>{transaction.accountName ?? "Unknown"}</td>
                            <td>{transaction.tickerSymbol}</td>
                            <td>{transaction.transactionType ?? "Unknown"}</td>
                            <td>{new Date(transaction.date).toLocaleDateString()}</td>
                            <td>{transaction.quantity?.toFixed(2)}</td>
                            <td>${transaction.price?.toFixed(2)}</td>
                            <td>${transaction.currentPrice?.toFixed(2)}</td>
                            <td>${Math.round(transaction.profitLoss)}</td>
                            <td>
                                Short Term(30%)
                            </td>
                            <td>${Math.round(transaction.profitLoss * 0.3)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Mobile Card View */}
            <div className="mobile-view">
                <div className="transaction-header-row">
                    <div className="header-item">Ticker</div>
                    <div className="header-item">Loss</div>
                    <div className="header-item">Tax Savings</div>
                </div>
                {sortedData.map((transaction, index) => (
                    <div
                        key={transaction.tickerSymbol + index}
                        className={`transaction-card ${
                            expandedTransaction === index ? "expanded" : ""
                        }`}
                    >
                        {/* Summary Section */}
                        <div
                            className="transaction-summary"
                            onClick={() => toggleExpand(index)}
                        >
                            <div className="transaction-header">
                                <span className="ticker">{transaction.tickerSymbol}</span>
                                <span className="profit">${Math.round(transaction.profitLoss)}</span>
                                <span className="tax-saving">
                                    ${Math.round(transaction.profitLoss * 0.3)}
                                </span>
                            </div>
                        </div>

                        {/* Details Section (conditionally displayed) */}
                        {expandedTransaction === index && (
                            <div className="transaction-details">
                                <p>
                                    <strong>Account Name:</strong> {transaction.accountName}
                                </p>
                                <p>
                                    <strong>Ticker:</strong> {transaction.tickerSymbol}
                                </p>
                                <p>
                                    <strong>Quantity:</strong> {transaction.quantity?.toFixed(2)}
                                </p>
                                <p>
                                    <strong>Purchase Price:</strong> $
                                    {transaction.price?.toFixed(2)}
                                </p>
                                <p>
                                    <strong>Current Price:</strong> $
                                    {transaction.currentPrice?.toFixed(2)}
                                </p>
                                <p>
                                    <strong>Potential Loss:</strong> $
                                    {Math.round(transaction.profitLoss)}
                                </p>
                                <p>
                                    <strong>Tax Type:</strong>{" "}
                                    {(() => {
                                        const currentDate = new Date();
                                        const transactionDate = new Date(transaction.date);
                                        const differenceInDays =
                                            (currentDate - transactionDate) /
                                            (1000 * 60 * 60 * 24);
                                        return differenceInDays > 365 ? "Long Term" : "Short Term";
                                    })()}
                                </p>
                                <p>
                                    <strong>Tax Savings:</strong> $
                                    {Math.round(transaction.profitLoss * 0.3)}
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TaxLossOpportunitiesFrame;
