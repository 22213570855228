import React, { useState } from "react";
import ManualTransactions from "./ManualTransactions";
import DemoDashboard from "./DemoDashboard";
import Playground from "./Playground";
import "./DemoComponent.css";

const DemoComponent = () => {
    const [transactions, setTransactions] = useState([
            {
                id: "1",
                instrument_id: "Apple",
                company_name: "Apple Inc.",
                isEquity: true,
                price: 150.0,
                costBasis: 150.0,
                quantity: 100.0,
                date: "2024-12-20T10:00:00",
                state: "FILLED",
                type: "BUY",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 0.0,
                capitalGainTaxType: null,
                settledQuantity: 0.0,
                settlementTransactions: [],
                accountName: "Robinhood demo account"
            },
            {
                id: "2",
                instrument_id: "Apple",
                company_name: "Apple Inc.",
                isEquity: true,
                price: 180.0,
                costBasis: 180.0,
                quantity: 100.0,
                date: "2025-01-15T10:00:00",
                state: "FILLED",
                type: "SELL",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 0.0,
                capitalGainTaxType: "SHORT",
                settledQuantity: 0.0,
                settlementTransactions: [],
                accountName: "Robinhood demo account"
            },
            {
                id: "3",
                instrument_id: "Apple",
                company_name: "Apple Inc.",
                isEquity: true,
                price: 110.0,
                costBasis: 110.0,
                quantity: 100.0,
                date: "2022-12-20T10:00:00",
                state: "FILLED",
                type: "BUY",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 0.0,
                capitalGainTaxType: null,
                settledQuantity: 0.0,
                settlementTransactions: [],
                accountName: "Etrade demo account"
            },
            {
                id: "4",
                instrument_id: "Apple",
                company_name: "Apple Inc.",
                isEquity: true,
                price: 185.0,
                costBasis: 185.0,
                quantity: 100.0,
                date: "2025-01-03T10:00:00",
                state: "FILLED",
                type: "SELL",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 0.0,
                capitalGainTaxType: "SHORT",
                settledQuantity: 0.0,
                settlementTransactions: [],
                accountName: "Etrade demo account"
            },
            {
                id: "15",
                instrument_id: "Apple",
                company_name: "Apple Inc.",
                isEquity: true,
                price: 0.0,
                costBasis: 0.0,
                quantity: 50.0,
                date: "2025-01-05T10:00:00",
                state: "FILLED",
                type: "DIVIDEND",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 50.0,
                capitalGainTaxType: null,
                settledQuantity: 0.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
            {
                id: "16",
                instrument_id: "Apple",
                company_name: "Apple Inc.",
                isEquity: true,
                price: 0.0,
                costBasis: 0.0,
                quantity: 50.0,
                date: "2025-01-01T10:00:00",
                state: "FILLED",
                type: "DIVIDEND",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 75.0,
                capitalGainTaxType: null,
                settledQuantity: 0.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
            {
                id: "5",
                instrument_id: "Tesla",
                company_name: "Tesla Inc.",
                isEquity: true,
                price: 1000.0,
                costBasis: 1000.0,
                quantity: 10.0,
                date: "2024-12-10T10:00:00",
                state: "FILLED",
                type: "BUY",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 0.0,
                capitalGainTaxType: null,
                settledQuantity: 10.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
            {
                id: "6",
                instrument_id: "Tesla",
                company_name: "Tesla Inc.",
                isEquity: true,
                price: 900.0,
                costBasis: 900.0,
                quantity: 10.0,
                date: "2025-01-15T10:00:00",
                state: "FILLED",
                type: "SELL",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: -1000.0,
                capitalGainTaxType: "SHORT",
                settledQuantity: 10.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
            {
                id: "7",
                instrument_id: "Google",
                company_name: "Alphabet Inc.",
                isEquity: true,
                price: 3000.0,
                costBasis: 3000.0,
                quantity: 5.0,
                date: "2023-11-15T10:00:00",
                state: "FILLED",
                type: "SELL",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: true,
                profitLoss: -500.0,
                capitalGainTaxType: null,
                settledQuantity: 5.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
            {
                id: "8",
                instrument_id: "Microsoft",
                company_name: "Microsoft Corp.",
                isEquity: true,
                price: 320.0,
                costBasis: 320.0,
                quantity: 10.0,
                date: "2023-12-01T10:00:00",
                state: "FILLED",
                type: "BUY",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 0.0,
                capitalGainTaxType: null,
                settledQuantity: 10.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
            {
                id: "9",
                instrument_id: "Microsoft",
                company_name: "Microsoft Corp.",
                isEquity: true,
                price: 310.0,
                costBasis: 310.0,
                quantity: 10.0,
                date: "2024-01-01T10:00:00",
                state: "FILLED",
                type: "SELL",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: -100.0,
                capitalGainTaxType: "SHORT",
                settledQuantity: 10.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
            {
                id: "10",
                instrument_id: "Microsoft",
                company_name: "Microsoft Corp.",
                isEquity: true,
                price: 315.0,
                costBasis: 315.0,
                quantity: 10.0,
                date: "2024-01-05T10:00:00",
                state: "FILLED",
                type: "BUY",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: true,
                profitLoss: 0.0,
                capitalGainTaxType: null,
                settledQuantity: 0.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
            {
                id: "11",
                instrument_id: "AMZN",
                company_name: "Amazon.com Inc.",
                isEquity: true,
                price: 300.0,
                costBasis: 300.0,
                quantity: 5.0,
                date: "2024-12-20T10:00:00",
                state: "FILLED",
                type: "BUY",
                optionType: null,
                optionSide: null,
                optionDirection: null,
                isDisallowed: false,
                profitLoss: 0.0,
                capitalGainTaxType: null,
                settledQuantity: 5.0,
                settlementTransactions: [],
                accountName: "Demo Account"
            },
    ]);

    return (
        <div className="demo-component-container">
            {/* Dashboard Section */}
            <div className="dashboard-section">
                <Playground transactions={transactions} />
            </div>

            {/* Manual Transactions Section */}
            <div className="transactions-section">
                <ManualTransactions transactions={transactions} setTransactions={setTransactions} />
            </div>
        </div>
    );
};

export default DemoComponent;
