import React, {useState} from "react";
import {useTransactions} from "./useTransactions";
import {useFilters} from "./useFilters";
import "./FrameStyles.css";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";
import CountUp from "react-countup";
import SummaryCard from "./SummaryCard";

const ITEMS_PER_PAGE = 5000000;

function CapitalGainTransactionsFrame({username}) {
    const [expandedTransaction, setExpandedTransaction] = useState(null); // Track expanded card
    const [sortConfig, setSortConfig] = useState({key: "taxesOwed", direction: "desc"}); // Default sort configuration

    const toggleExpand = (index) => {
        setExpandedTransaction(expandedTransaction === index ? null : index); // Use index to track the expanded card
    };

    const {data, loading, error} = useTransactions(
        "transactions/getCurrentYearTaxableProfit",
        username
    );

    const {
        filteredData: unpagedData,
        totalPages,
        setYearFilter,
        setTickerFilter,
        currentPage,
        setCurrentPage,
    } = useFilters(data, ITEMS_PER_PAGE);

    // Sort filtered data based on the current sort configuration
    const sortedData = React.useMemo(() => {
        if (!sortConfig.key) return unpagedData; // No sorting if no sort key is set

        return [...unpagedData].sort((a, b) => {
            const aValue =
                sortConfig.key === "taxesOwed"
                    ? Math.abs(a.profitLoss) * (a.capitalGainTaxType === "SHORT" ? 0.3 : 0.15)
                    : a[sortConfig.key];
            const bValue =
                sortConfig.key === "taxesOwed"
                    ? Math.abs(b.profitLoss) * (b.capitalGainTaxType === "SHORT" ? 0.3 : 0.15)
                    : b[sortConfig.key];

            if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [unpagedData, sortConfig]);

    // Calculate total taxes owed and total capital gain
    const totalTaxesOwed = sortedData.reduce((acc, transaction) => {
        const taxType = transaction.capitalGainTaxType;
        const taxRate = taxType === "SHORT" ? 0.3 : 0.15;
        return acc + Math.abs(transaction.profitLoss * taxRate);
    }, 0);

    const totalCapitalGain = sortedData.reduce((acc, transaction) => {
        return acc + Math.abs(transaction.profitLoss);
    }, 0);

    // Sorting handler
    const handleSort = (key) => {
        setSortConfig((prev) => {
            const direction = prev.key === key && prev.direction === "asc" ? "desc" : "asc";
            return {key, direction};
        });
    };

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Lottie
                    animationData={loadingAnimation}
                    style={{height: "150px", width: "150px"}} // Adjust loader size
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}, please contact support for help: support@profitree-tax.com</div>;
    }

    return (
        <div>
            <div className="summary-container">
                <SummaryCard title="Total Capital Gains" value={totalCapitalGain} prefix="$"/>
                <SummaryCard title="Total Taxes Owed" value={totalTaxesOwed} prefix="$"/>
            </div>
            <div className="filters">
                <label>
                    Year:
                    <select onChange={(e) => setYearFilter(e.target.value)}>
                        <option value="">All</option>
                        {[...new Set(data.map((d) => new Date(d.date).getFullYear()))].map(
                            (year) => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            )
                        )}
                    </select>
                </label>
                <label>
                    Ticker Symbol:
                    <input
                        type="text"
                        placeholder="Enter ticker symbol"
                        onChange={(e) => setTickerFilter(e.target.value)}
                    />
                </label>
            </div>

            {/* Desktop Table View */}
            <div className="table-container desktop-view">
                <table className="transactions-table">
                    <thead>
                    <tr>
                        <th onClick={() => handleSort("accountName")}>Account Name</th>
                        <th onClick={() => handleSort("tickerSymbol")}>Ticker</th>
                        <th onClick={() => handleSort("transactionType")}>Transaction Type</th>
                        <th onClick={() => handleSort("purchaseDate")}>Purchase Date</th>
                        <th onClick={() => handleSort("purchasePrice")}>Purchase Price</th>
                        <th onClick={() => handleSort("quantity")}>Sold Quantity</th>
                        <th onClick={() => handleSort("soldDate")}>Sold Date</th>
                        <th onClick={() => handleSort("soldPrice")}>Sold Price</th>
                        <th onClick={() => handleSort("profitLoss")}>Profit Amt</th>
                        <th>Tax Type</th>
                        <th onClick={() => handleSort("taxesOwed")}>Taxes Owed</th>
                    </tr>
                    </thead>
                    <tbody>
                    {sortedData.map((transaction, index) => (
                        <tr key={index}>
                            <td>{transaction.accountName ?? "Unknown"}</td>
                            <td>{transaction.tickerSymbol ?? "Dividend"}</td>
                            <td>{transaction.transactionType ?? "Unknown"}</td>
                            <td>
                                {transaction.settlementTransactions[0]?.date != null
                                    ? new Date(transaction.settlementTransactions[0].date).toLocaleDateString()
                                    : new Date(transaction.date).toLocaleDateString()}
                            </td>
                            <td>
                                $
                                {transaction.settlementTransactions[0]?.price != null
                                    ? transaction.settlementTransactions[0].price.toFixed(2)
                                    : 0.0}
                            </td>
                            <td>
                                {transaction.quantity != null
                                    ? transaction.quantity.toFixed(2)
                                    : "Dividend"}
                            </td>
                            <td>
                                {transaction.date
                                    ? new Date(transaction.date).toLocaleDateString()
                                    : "Dividend"}
                            </td>
                            <td>
                                $
                                {transaction.price != null && transaction.price !== 0.0
                                    ? transaction.price.toFixed(2)
                                    : 1.00}
                            </td>
                            <td>
                                $
                                {transaction.profitLoss != null
                                    ? Math.round(transaction.profitLoss)
                                    : "Dividend"}
                            </td>
                            <td>
                                {transaction.capitalGainTaxType === "LONG"
                                    ? "Long Term(15%)"
                                    : transaction.capitalGainTaxType === "SHORT"
                                        ? "Short Term(30%)"
                                        : "Dividend(30%)"}
                            </td>
                            <td>
                                {(() => {
                                    const taxType = transaction.capitalGainTaxType;
                                    if (transaction.profitLoss != null) {
                                        return taxType === "LONG"
                                            ? `$${Math.round(transaction.profitLoss * 0.15)}`
                                            : `$${Math.round(transaction.profitLoss * 0.30)}`;
                                    }
                                    return "Dividend";
                                })()}
                            </td>

                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>

            {/* Mobile Card View */}
            <div className="mobile-view">
                <div className="transaction-header-row">
                    <div className="header-item">Ticker</div>
                    <div className="header-item">Profit</div>
                    <div className="header-item">Taxes Owed</div>
                </div>
                {sortedData.map((transaction, index) => (
                    <div
                        key={transaction.tickerSymbol + index} // Ensure unique key
                        className={`transaction-card ${
                            expandedTransaction === index ? "expanded" : ""
                        }`}
                    >
                        {/* Summary Section */}
                        <div
                            className="transaction-summary"
                            onClick={() => toggleExpand(index)} // Toggle details on click
                        >
                            <div className="transaction-header">
                                <span className="ticker">{transaction.tickerSymbol}</span>
                            </div>
                            <div className="transaction-header">
                                <span className="profit"> ${Math.round(transaction.profitLoss)}</span>
                            </div>
                            <div className="transaction-date">
                                {(() => {
                                    const taxType = transaction.capitalGainTaxType;
                                    return taxType === "LONG"
                                        ? `$${Math.round(transaction.profitLoss * 0.15)}`
                                        : `$${Math.round(transaction.profitLoss * 0.30)}`;
                                })()}
                            </div>
                        </div>

                        {/* Details Section (conditionally displayed) */}
                        {expandedTransaction === index && (
                            <div className="transaction-details">
                                <p>
                                    <strong>Account Name:</strong> {transaction.accountName}
                                </p>
                                <p>
                                    <strong>Ticker:</strong> {transaction.tickerSymbol}
                                </p>
                                <p>
                                    <strong>Quantity:</strong> {transaction.quantity?.toFixed(2)}
                                </p>
                                <p>
                                    <strong>Purchase Price:</strong> $
                                    {transaction.settlementTransactions[0].price?.toFixed(2)}
                                </p>
                                <p>
                                    <strong>Purchase Date:</strong> $
                                    {new Date(transaction.settlementTransactions[0].date).toLocaleDateString()}
                                </p>
                                <p>
                                    <strong>Sold Price:</strong> ${transaction.price?.toFixed(2)}
                                </p>
                                <p>
                                    <strong>Sold Date:</strong>{" "}
                                    {new Date(transaction.date).toLocaleDateString()}
                                </p>
                                <p>
                                    <strong>Profit Amount:</strong> $
                                    {Math.round(transaction.profitLoss)}
                                </p>
                                <p>
                                    <strong>Tax Type:</strong>{" "}
                                    {transaction.capitalGainTaxType === "LONG" ? "Long Term" : "Short Term"}
                                </p>
                                <p>
                                    <strong>Taxes Owed:</strong>{" "}
                                    {(() => {
                                        const taxType = transaction.capitalGainTaxType;
                                        return taxType === "LONG"
                                            ? `$${Math.round(transaction.profitLoss * 0.15)}`
                                            : `$${Math.round(transaction.profitLoss * 0.30)}`;
                                    })()}
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>

    );
}



export default CapitalGainTransactionsFrame;
