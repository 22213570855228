import React, {useState} from "react";
import {usePlaygroundTransactions} from "./usePlaygroundTransactions";
import {useFilters} from "./useFilters";
import "./FrameStyles.css";
import loadingAnimation from "../assets/Loading.json";
import Lottie from "lottie-react";
import CountUp from "react-countup";
import SummaryCard from "./SummaryCard";

const ITEMS_PER_PAGE = 5000000;

function PlaygroundCapitalGainTransactionsFrame({transactions}) {
    const [expandedTransaction, setExpandedTransaction] = useState(null); // Track expanded card
    const [sortConfig, setSortConfig] = useState({key: "taxesOwed", direction: "desc"}); // Default sort configuration

    const toggleExpand = (index) => {
        setExpandedTransaction(expandedTransaction === index ? null : index); // Use index to track the expanded card
    };

    const { data, loading, error } = usePlaygroundTransactions(
        "playgroundTransactions/getCurrentYearTaxableProfit",
        transactions
    );

    const {
        filteredData: unpagedData,
        totalPages,
        setYearFilter,
        setTickerFilter,
        currentPage,
        setCurrentPage,
    } = useFilters(data, ITEMS_PER_PAGE);

    // Sort filtered data based on the current sort configuration
    const sortedData = React.useMemo(() => {
        if (!sortConfig.key) return unpagedData; // No sorting if no sort key is set

        return [...unpagedData].sort((a, b) => {
            const aValue =
                sortConfig.key === "taxesOwed"
                    ? Math.abs(a.profitLoss) * (a.capitalGainTaxType === "SHORT" ? 0.3 : 0.15)
                    : a[sortConfig.key];
            const bValue =
                sortConfig.key === "taxesOwed"
                    ? Math.abs(b.profitLoss) * (b.capitalGainTaxType === "SHORT" ? 0.3 : 0.15)
                    : b[sortConfig.key];

            if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [unpagedData, sortConfig]);

    // Calculate total taxes owed and total capital gain
    const totalTaxesOwed = sortedData.reduce((acc, transaction) => {
        const taxType = transaction.capitalGainTaxType;
        const taxRate = taxType === "SHORT" ? 0.3 : 0.15;
        return acc + Math.abs(transaction.profitLoss * taxRate);
    }, 0);

    const totalCapitalGain = sortedData.reduce((acc, transaction) => {
        return acc + Math.abs(transaction.profitLoss);
    }, 0);

    // Sorting handler
    const handleSort = (key) => {
        setSortConfig((prev) => {
            const direction = prev.key === key && prev.direction === "asc" ? "desc" : "asc";
            return {key, direction};
        });
    };

    if (loading) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Lottie
                    animationData={loadingAnimation}
                    style={{height: "150px", width: "150px"}} // Adjust loader size
                />
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}, please contact support for help: support@profitree-tax.com</div>;
    }

    return (
        <div>
            <div className="summary-container">
                <SummaryCard title="Total Capital Gains" value={totalCapitalGain} prefix="$"/>
                <SummaryCard title="Total Taxes Owed" value={totalTaxesOwed} prefix="$"/>
            </div>
        </div>

    );
}



export default PlaygroundCapitalGainTransactionsFrame;
