import taxLossHarvestingImage from '../assets/tax_loss_haresting.jpg';
import washSalesImage from '../assets/wash_sale.png';
import costBasisImage from '../assets/cost_basis.png';

const blogData = [
    {
        id: 'tax-loss-harvesting',
        title: "What Is Tax-Loss Harvesting and Why It Matters for DIY Investors?",
        image: taxLossHarvestingImage,
        date: 'January 16th, 2025',
        content: `
            <p>Tax-loss harvesting is a powerful strategy for investors to reduce their tax burden and optimize portfolio performance. It's a way to offset capital gains with losses while maintaining your long-term investment strategy.</p>
            <h2>What Is Tax-Loss Harvesting?</h2>
            <p>Tax-loss harvesting involves selling investments at a loss to offset capital gains tax. You can even use losses to offset up to $3,000 of your ordinary income each year.</p>
            <h3>Example</h3>
            <p>Imagine you made a $1,000 profit from selling Stock A but incurred a $500 loss from selling Stock B. By harvesting the $500 loss, your taxable gain reduces to $500.</p>
            <h2>Benefits of Tax-Loss Harvesting</h2>
            <ul>
                <li>Reduces your taxable income.</li>
                <li>Offsets gains in a tax-efficient manner.</li>
                <li>Improves your long-term portfolio performance.</li>
            </ul>
            <h2>How ProfiTree Can Help</h2>
            <p>ProfiTree automates the tax-loss harvesting process, ensuring you maximize savings without worrying about calculations or IRS compliance.</p>
        `,
    },
    {
        id: 'wash-sales',
        title: "The Hidden Cost of Wash Sales: How to Avoid Common Pitfalls",
        image: washSalesImage,
        date: 'January 9th, 2025',
        content: `
            <p>Wash sales are a major pitfall that can derail your tax-loss harvesting strategy. The IRS disallows claiming losses on sales if you repurchase the same or substantially identical investment within 30 days.</p>
            <h2>What Is a Wash Sale?</h2>
            <p>A wash sale occurs when you sell an investment at a loss and repurchase it (or a similar one) within a 30-day window before or after the sale.</p>
            <h3>Example</h3>
            <p>If you sell shares of Company A on January 1 for a loss and repurchase them on January 15, the loss will be disallowed for tax purposes.</p>
            <h2>How to Avoid Wash Sales</h2>
            <ul>
                <li>Wait at least 31 days before repurchasing the same investment.</li>
                <li>Buy similar, but not identical, investments like ETFs to maintain exposure.</li>
                <li>Use tools like ProfiTree to track transactions and identify potential wash sale risks.</li>
            </ul>
            <h2>Why It Matters</h2>
            <p>Wash sales can negate the benefits of tax-loss harvesting, making it essential to track your trades carefully and avoid triggering them.</p>
        `,
    },
    {
        id: 'cost-basis-strategies',
        title: "5 Cost Basis Strategies to Optimize Gains and Losses",
        image: costBasisImage,
        date: 'January 2nd, 2025',
        content: `
            <p>Cost basis is the original value of an asset for tax purposes. The strategy you choose to calculate cost basis can significantly impact your taxable gains or losses.</p>
            <h2>What Is Cost Basis?</h2>
            <p>Cost basis is the price you paid for an investment, adjusted for commissions, splits, and dividends. It's used to calculate your capital gain or loss when you sell the asset.</p>
            <h2>Top 5 Cost Basis Strategies</h2>
            <ul>
                <li><strong>FIFO (First In, First Out):</strong> The oldest shares are sold first. Best for rising markets.</li>
                <li><strong>LIFO (Last In, First Out):</strong> The newest shares are sold first. Useful in falling markets.</li>
                <li><strong>High Cost:</strong> Shares with the highest cost basis are sold first, minimizing taxable gains.</li>
                <li><strong>Low Cost:</strong> Shares with the lowest cost basis are sold first, maximizing taxable gains (useful for loss harvesting).</li>
                <li><strong>Average Cost:</strong> The cost basis is averaged across all shares, simplifying calculations.</li>
            </ul>
            <h2>Choosing the Right Strategy</h2>
            <p>The optimal cost basis strategy depends on your financial goals, market conditions, and tax situation. ProfiTree helps you analyze and select the best strategy for your needs.</p>
        `,
    },
];

export default blogData;
