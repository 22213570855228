import React from 'react';
import { Link } from 'react-router-dom';
import './BlogCard.css';

const BlogCard = ({ image, title, date, description, url }) => {
    return (
        <nav className="nav-links">
        <Link to={url} className="blog-card">
            <img className="blog-card-image" src={image} alt={title} />
            <div className="blog-card-content">
                <h2 className="blog-card-title">{title}</h2>
                <p className="blog-card-date">{date}</p>
                <p className="blog-card-description">{description}</p>
            </div>
        </Link>
        </nav>
    );
};

export default BlogCard;
