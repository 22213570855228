import React, { useState } from 'react';
import { FaBars, FaTimes, FaUserCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/Presentation1.png';
import { useNavigate } from 'react-router-dom';

function Header({ username }) {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };


    const handleLogout = () => {
        localStorage.removeItem('username'); // Clear persistence
        navigate("/");
        window.location.reload();
    };

    return (
        <header className="header">
            {/* Logo */}
            <div className="logo-container">
                <Link to="/">
                    <img src={logo} alt="Logo" className="logo" />
                </Link>
            </div>

            {/* Hamburger Menu */}
            <button className="menu-toggle" onClick={toggleMenu}>
                {menuOpen ? <FaTimes /> : <FaBars />}
            </button>

            {/* Dropdown Menu for Mobile */}
            <nav className={`nav-links ${menuOpen ? 'active' : ''}`}>
                <Link to="/account" onClick={toggleMenu}>Account</Link>
                <Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link>
                <Link to="/about" onClick={toggleMenu}>About Us</Link>
                <Link to="/pricing" onClick={toggleMenu}>Pricing</Link>
                <Link to="/blog" onClick={toggleMenu}>Blog</Link>
                <Link to="/learning" onClick={toggleMenu}>FAQ</Link>
                <div className="header-buttons">
                    {username ? ( // Directly check for username prop
                        <div className="user-account" onClick={toggleDropdown}>
                            <FaUserCircle className="user-icon" />
                            <span className="username">{username}</span>
                            {showDropdown && (
                                <div className="dropdown-menu">
                                    <a href="/reset-password">Reset Password</a>
                                    <a href="/contact-us">Contact Us</a>
                                    <button className="btn btn-demo" onClick={handleLogout}>Logout</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <Link to="/auth">
                                <button className="btn btn-demo" onClick={toggleMenu}>Log In</button>
                            </Link>
                        </>
                    )}
                </div>
            </nav>
        </header>
    );
}

export default Header;
