import React from "react";
import "./MissionHowItWorks.css";
import { FaLink, FaSearchDollar, FaLightbulb, FaChartLine, FaBell } from "react-icons/fa";

function MissionHowItWorks() {
    const steps = [
        {
            title: "Connect Your Accounts",
            description: "Link your brokerage accounts securely to ProfiTree to start monitoring for tax-saving opportunities.",
            icon: <FaLink />,
        },
        {
            title: "Analyze Past Transactions",
            description: "Our platform scans your transaction history to identify missed opportunities, such as unclaimed tax losses or overlooked cost basis adjustments.",
            icon: <FaSearchDollar />,
        },
        {
            title: "Get Actionable Suggestions",
            description: "Receive personalized recommendations to avoid wash sales and optimize your tax savings. ProfiTree helps you make informed decisions to keep more money in your pocket.",
            icon: <FaLightbulb />,
        },
        {
            title: "Never Miss a Thing With Smart Alerts",
            description: "Stay on top of your tax strategies with our intuitive dashboard. Track your realized savings, potential opportunities, and overall progress throughout the year.",
            icon: <FaBell />,
        },
    ];

    return (
        <section className="mission-how-it-works">
            <div className="mission-content-container">
                <h2 className="section-heading">Our Mission</h2>
                <p className="mission-text">
                    ProfiTree’s mission is to make tax-loss harvesting accessible and simple for every investor. We empower
                    DIY investors to minimize their tax burdens, optimize their portfolios, and take full control of their
                    financial future—all without the high costs of traditional advisors.
                </p>
            </div>
            <div className="mission-content-container">
                <h2 className="section-heading">How ProfiTree Can Transform Your Tax Strategies</h2>
                <p className="mission-text">
                    Managing your investments is rewarding, but navigating tax rules can be overwhelming and complex.
                    ProfiTree is here to simplify your tax-loss harvesting, helping you save money and make smarter investment decisions effortlessly
                </p>
                <div className="steps-grid">
                    {steps.map((step, index) => (
                        <div className="step-card" key={index}>
                            <div className="step-icon">{step.icon}</div>
                            <h3 className="step-title">{step.title}</h3>
                            <p className="step-description">{step.description}</p>
                        </div>
                    ))}
                </div>
                {/* Add CTA Buttons Here */}
                <div className="cta-container">
                    <button
                        className="cta-button"
                        onClick={() => {
                            window.location.href = "/signup"; // Replace with your sign-up URL
                        }}
                    >
                        Start Saving Today
                    </button>

                    <button
                        className="learn-more-button"
                        onClick={() => {
                            window.location.href = "/about"; // Replace with your learn TLH URL
                        }}
                    >
                        ProfiTree's Mission
                    </button>
                </div>
            </div>
        </section>
    );
}

export default MissionHowItWorks;
