import React from "react";
import './About.css';

const About = () => {
    return (
        <div className="about-section">
            <h1>About ProfiTree Team</h1>

            <p>
                ProfiTree is a cutting-edge tax-loss harvesting platform designed for DIY investors who want to manage their portfolios efficiently while maximizing their tax savings. By offering tools to avoid wash sales, optimize cost basis strategies, and identify potential tax-loss harvesting opportunities, ProfiTree empowers users to take control of their investment strategy without relying on costly robo-advisors.
            </p>

            <hr />

            <h2>Our Mission</h2>
            <p>
                At ProfiTree, our mission is to simplify tax-loss harvesting for individual investors. We aim to bridge the gap between professional-grade portfolio optimization tools and everyday investors, ensuring that everyone can make informed financial decisions.
            </p>

            <hr />

            <h2>Why Choose ProfiTree?</h2>
            <ul>
                <li>
                    <strong>Ease of Use:</strong> Intuitive tools and interfaces make tax optimization straightforward and accessible to everyone.
                </li>
                <li>
                    <strong>Cost-Effective:</strong> Say goodbye to expensive advisors and robo-services. ProfiTree offers a budget-friendly solution with powerful features.
                </li>
                <li>
                    <strong>Transparency:</strong> Full visibility into your portfolio and tax strategies.
                </li>
            </ul>

            <hr />

            <h2>Our Features</h2>
            <ul>
                <li><strong>Current Year Capital Gains:</strong> Track and understand your realized capital gains for the current year.</li>
                <li><strong>Current Year Capital Losses:</strong> Monitor realized capital losses and their impact on your taxes.</li>
                <li><strong>Potential Tax Saving Opportunities:</strong> Discover ways to optimize your portfolio for additional tax savings.</li>
                <li><strong>Past Wash Sale Transactions:</strong> Review previous wash sale transactions and understand their tax implications.</li>
                <li><strong>Wash Sale Avoidance:</strong> Identify trades that may trigger wash sales and receive recommendations to avoid them.</li>
            </ul>

            <hr />

            <h2>Our Story</h2>
            <p>
                ProfiTree was born out of a desire to make tax-loss harvesting more accessible to everyday investors. Frustrated by the complexities of tax optimization and the high costs of professional advisors, our founder created ProfiTree to simplify the process. Over the past few months, we’ve developed a robust platform that caters to the unique needs of DIY investors.
            </p>

            <hr />

            <h2>Get in Touch</h2>
            <p>
                Have questions or need support? We’re here to help! Reach out to us anytime at:
            </p>
            <address>
                <strong>Email:</strong> support@profitree-tax.com
            </address>

            <p>
                Join the ProfiTree community today and take control of your tax-loss harvesting strategies!
            </p>
        </div>
    );
};

export default About;
