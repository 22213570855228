import React from "react";
import CountUp from "react-countup";
import "./FrameStyles.css";

function SummaryCard({ title, value, prefix = "", suffix = "" }) {
    return (
        <div className="summary-card">
            <h3>{title}</h3>
            <p className="summary-value">
                <CountUp
                    start={0}
                    end={value}
                    duration={1.5} // Animation duration in seconds
                    separator=","
                    decimals={2} // Number of decimal places
                    prefix={prefix}
                    suffix={suffix}
                />
            </p>
        </div>
    );
}

export default SummaryCard;
