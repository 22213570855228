import { useState, useEffect } from "react";
import axios from "axios";

const backendUrl = "https://profitree-backend-9482feeffc4a.herokuapp.com";

export const usePlaygroundTransactions = (endpoint, transactions) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${backendUrl}/${endpoint}`, transactions, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                setData(response.data);
            } catch (err) {
                setError(err.message || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        if (transactions && transactions.length > 0) {
            fetchData();
        } else {
            setLoading(false);
        }
    }, [endpoint, transactions]); // Added transactions as a dependency

    return { data, loading, error };
};
